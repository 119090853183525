@import "../../../styles/_chunk";

.HitCardPopupCriticScore {
  display: flex;
  align-items: center;

  &__score {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $metacritic-green;
    color: $black;
    width: 24px;
    height: 24px;
    font-weight: 700;
    margin-right: 0.5rem;
    font-size: 0.75rem;
    border-radius: $border-radius-xs;
  }

  &__logo {
    img {
      width: 24px;
      height: 24px;
    }
  }
}
