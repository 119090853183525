@import "../../../styles/_chunk";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInAnimation {
  animation: fadeIn 0.2s ease-out;
}

.HitCardPopup {
  width: 400px;
  // height: 442px;
  background-color: var(--card-background-color);
  border-radius: $border-radius;
  position: absolute;
  z-index: 10;
  box-shadow: 0 4px 50px 0 rgba(0, 0, 0, 0.5);

  &--isBigCard {
    width: 490px;
    // height: 502px;
  }

  .HitCardPopup__cover {
    .product-cover-container {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    img {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .HitCardPopup__content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .HitCardPopup__content__titleButton {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 0.5rem;
    // margin-bottom: -0.75rem;
  }

  .HitCardPopup__title {
    font-size: 1.1rem;
    font-weight: 700;
    color: var(--site-font-color);
  }

  .HitCardPopup__wishlistButtonContainer {
    z-index: 20;

    .card-actions-container {
      display: none;
    }

    .wishlist-icon {
      button {
        background-color: transparent;
        color: $primary-core !important;
        border: none;
        // border: 1px solid $grey-10;
        border-radius: $border-radius-sm;

        &:hover {
          // border-color: $primary-core !important;
          color: darken($primary-core, 10%) !important;
        }
      }
    }

    .wishlist-button {
      button {
        margin: 0;
        padding: 0;

        &:hover {
          color: $primary-core !important;
        }
      }
    }
  }

  .HitCardPopup__content__reviews {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: -0.75rem;
  }

  .HitCardPopup__content__reviews:empty {
    display: none;
  }

  .tag-column-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
    position: relative;
    gap: 8px;

    .tag-column {
      flex: 0 0 auto;
      display: inline-flex;

      &:last-child {
        padding-right: 0;
      }

      &.hidden {
        display: none;
      }
    }

    .tag {
      color: $white;
      border-radius: $border-radius-sm;
      font-size: 12px;
      font-weight: 400;
      padding: 0.125rem 0.375rem;
      white-space: nowrap;
      background-color: var(--tag-background-color);

      // @include theme(light) {
      //   background-color: $grey-3;
      //   color: $text-black;
      // }
    }
  }

  .star-rating-container .star svg {
    width: 18px;
  }

  .HitCardPopup__content__shortDesc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.813rem;
  }

  .HitCardPopUp__DrmPriceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .HitCardPopUp__DrmContainer {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .hitCardStripe__content__icons__nonGame {
      display: flex;
      align-items: center;
      gap: 4px;
      text-transform: uppercase;
      font-size: 1rem;

      @include theme(light) {
        color: $card-icons-color-dark !important;
      }
    }

    .hitCardStripe__content__icons__os {
      display: flex;
      align-items: center;

      @include theme(light) {
        color: $card-icons-color-dark !important;
      }
    }
  }

  .hitCardStripe__content__icons__os {
    gap: 0.5rem;
  }

  .hitCardStripe__content__purchase {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .AddToCartBtn {
      height: 33px;
      // width: 70px;
      justify-content: center;
      font-weight: 700;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .in-cart-text {
        display: none;
      }
    }
  }
}
