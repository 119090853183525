h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: var(--site-font-weight-bold) !important;
}

h1,
.h1 {
  font-size: 1.375rem;
  line-height: 1.625rem;

  @include media-breakpoint-up(xl) {
    font-size: 2rem;
    line-height: 2.375rem;
  }
}

h2,
.h2 {
  font-size: 1.25rem;
  line-height: 1.5rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.75rem;
    line-height: 2.125rem;
  }
}

h3,
.h3 {
  font-size: 1.125rem;
  line-height: 1.375rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
    line-height: 1.8125rem;
  }
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.1875rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 0.9375rem;
  line-height: 1.125rem;

  @include media-breakpoint-up(xl) {
    font-size: 1.125rem;
    line-height: 1.375rem;
  }
}

h6,
.h6 {
  font-size: 0.9375rem;
}

.paragraph-small {
  font-size: 13px;

  @include media-breakpoint-up(xl) {
    font-size: 14px;
  }
}
