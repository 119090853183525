@import "../../../styles/_chunk";

.HitcardPopupScreenshotCarousel {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;

  .HitcardPopupScreenshotCarousel__slider {
    height: 100%;
    width: 100%;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    > div {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }

  .screenshot-image {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .arrow-btn-container {
    .slick-arrow {
      z-index: 3;

      &:hover {
        svg {
          transform: scale(1.3);
        }
      }
    }
  }

  .slick-slider .arrow-btn-container {
    .btn-right {
      margin-right: 0.875rem !important;
    }

    svg {
      color: $grey-2;
      width: 30px;
      height: 30px;
    }
  }

  .slick-slider .arrow-btn-container.btn-left {
    margin-left: 0.875rem !important;
  }

  .slick-slider .arrow-btn-container.btn-right {
    margin-right: 0.875rem !important;
  }
}
