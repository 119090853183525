// Main Site Theme

@mixin theme($name) {
  .site-#{$name}-theme & {
    @content;
  }
}

// Brand Class used to target an individual brand with lots of custom CSS
// Example
// @include brand(hpomen) {}

@mixin brand($name) {
  .brand-#{$name} & {
    @content;
  }
}

// Brand Property
// Example usage in scss files
// @include brand-property(background-color, brand-core);
// @include brand-property(background-color, brand-core, important);

@mixin brand-property($property, $value, $isImportant: false) {
  @if $isImportant {
    #{$property}: var(--#{$value}) !important;
  }
  @else {
    #{$property}: var(--#{$value});
  }
}

/* stylelint-disable */
@mixin hover-supported {
  @media all and (hover: hover), (min--moz-device-pixel-ratio: 0) {
    &:hover {
      @content;
    }
  }
}
/* stylelint-enable */
