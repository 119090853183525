$cdn:                                 'https://cdn.fanatical.com/production';

$text-black:                          $grey-13;
$text-white:                          $grey-2;

$link-color:                          $grey-5;

$body-bg:                             $grey-14;
$body-color:                          $grey-2;

$font-family-sans-serif:              'Lato', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC', 'Noto Sans TC', 'Open Sans', 'Lucida Grande', 'Tahoma', sans-serif;
$hpomen-font-family:                  'Montserrat', 'Verdana', sans-serif;

// These sizes overwrite some of the original base bootstrap styling
$font-size-base:                      1rem !default;
$font-size-lg:                        $font-size-base + 0.25rem !default;   // Equivalent to 20px when base is 16px
$font-size-sm:                        $font-size-base - 0.125rem !default;  // Equivalent to 14px when base is 16px
$font-size-xs:                        $font-size-base - 0.25rem !default;   // Equivalent to 12px when base is 16px

$h1-font-size:                        $font-size-base * 2 !default;
$h2-font-size:                        $font-size-base * 1.75 !default;
$h3-font-size:                        $font-size-base * 1.5 !default;
$h4-font-size:                        $font-size-base * 1.375 !default;
$h5-font-size:                        $font-size-base * 1.25 !default;
$h6-font-size:                        $font-size-base !default;

$font-weight-regular:                 300;
$font-weight-medium:                  400;
$font-weight-bold:                    700;
$font-weight-heavy:                   900;

$line-height-base:                    1.6 !default;

$enable-rounded:                      true;
$border-radius-xs:                    2.5px;
$border-radius-sm:                    4px;
$border-radius:                       8px;
$border-radius-lg:                    12px;
$border-radius-xl:                    16px;
$border-primary:                      0.15rem solid $primary-core;

$card-spacer-x:                       0.35rem;
$card-border-width:                   0;
$card-border-radius-inner:            3px;
$card-carousel-margin:                8px;
$hit-card-stripe-height:              3rem;

$blog-bg-color:                       $grey-2;
$blog-bg-inverse-color:               $white;

$btn-primary-color:                   $font-color-dark;
$btn-secondary-color:                 $font-color-light;
$btn-secondary-bg:                    $grey-13;
$btn-secondary:                       $font-color-dark;
$btn-secondary-border-color:          $grey-13;
$btn-outline-secondary-color:         $font-color-dark;
$btn-outline-secondary-border-color:  $btn-outline-secondary-color;

$grid-gutter-width-base:              1rem;

$dropdown-divider-bg:                 $grey-2;

$hr-border-color:                     rgba($font-color-light, 0.1);
$hr-border-width:                     0.06rem;

$modal-content-bg:                    $grey-2;
$modal-backdrop-opacity:              0.75;

$table-bg:                            $grey-8;
$table-bg-head:                       $grey-13;
$table-dark-color:                    $font-color-light;
$table-dark-bg:                       $grey-8;
$table-cell-padding:                  1em 1.5em;

$pagination-bg:                       $grey-13;
$pagination-border-color:             $grey-13;
$pagination-hover-border-color:       $error-core;
$pagination-hover-color:              $font-color-light;
$pagination-hover-bg:                 $grey-10;
$pagination-active-bg:                $grey-10;
$pagination-active-color:             $grey-2;

$breadcrumb-font-size:                null;
$breadcrumb-padding-y:                0;
$breadcrumb-padding-x:                0;
$breadcrumb-item-padding-x:           0.5rem;
$breadcrumb-margin-bottom:            0;
$breadcrumb-bg:                       $body-bg;
$breadcrumb-divider-color:            $grey-7;
$breadcrumb-active-color:             $grey-7;
$breadcrumb-divider:                  quote(">");
$breadcrumb-divider-flipped:          $breadcrumb-divider;
$breadcrumb-border-radius:            0;

$fa-font-path:                        "~font-awesome/fonts";
$fa-inverse:                          $grey-14;

$retina-screen:                       "(min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx)";

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.5),
    6: ($spacer * 4)
  ),
  $spacers
);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1520px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
);
